import React from 'react'
import {Helmet} from 'react-helmet'
import '../styles/index.sass'

const Layout = ({ children, data }) => (
  <div style={{height: '100%'}}>
    <Helmet>
      <title>{data.site.siteMetadata.title}</title>
      <meta name='description' content='Alge-Bingo'></meta>
      <meta name='keywords' content='ios, games, education, educational game, math, stem'></meta>
    </Helmet>
    <div style={{height: '100%'}}>
      {children}
    </div>
  </div>
)

export default Layout

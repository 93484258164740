import React from 'react'
import {graphql} from 'gatsby'
import Navbar from '../components/Navbar.js'
import Layout from '../layouts/index'
import logoText from '../assets/logo-text.png'
import appStore from '../assets/app-store.svg'
import devices from '../assets/web-devices.png'

export const query = graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`

const IndexPage = ({ data }) => (
  <Layout data={data}>
    <div className='parent'>
      <Navbar />
      <div className='primary-background'></div>
      <div className='container is-flex content-container'>

        <div className='is-vertical-flex'>
          <div className='spacer' />
          <div className='logo-text'>
            <img src={logoText} alt='Alge-Bingo-Logo' />
          </div>
          <div className='description-text'>
            An algebra Bingo game for everyone!<br/>
            Develop your mental equation solving ability.
          </div>
          <div className='spacer' />
          <a href='https://apps.apple.com/us/app/alge-bingo/id490498376?ls=1' target='_blank' rel="noopener noreferrer" className='app-store-link'>
            <img src={appStore} alt='app-store-link' />
          </a>
        </div>

        <div className='devices-container'>
          <img src={devices} alt='devices-with-app' />
        </div>
      </div>
        
    </div>
  </Layout>
)

export default IndexPage

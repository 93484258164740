import React from 'react'
import Link from 'gatsby-link'
import '../styles/navbar.sass'
import logo from '../assets/logo-shadow.png'
// <img src={logo} alt='brand' />

const Navbar = ({noLink}) => (
  <div className='container is-flex to-the-edges navbar'>
      <Link to='/' className='nav-link' className='app-logo'>
        <img src={logo} alt='app-logo' />
      </Link>
      <nav className='nav-menu'>
        {noLink || <Link to='/privacy' className='nav-link'>Privacy</Link>}
      </nav>
  </div>
)

export default Navbar
